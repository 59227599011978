import './App.css';
import Login from './components/Login/Login';
import SignUp from './components/SignUp/SignUp';
import MasterPage from './components/MasterPage/MasterPage';
import { Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage/LandingPage';
import JobSeekerPicker from './components/JobSeekerPicker/JobSeekerPicker';

import RecruiterView from './components/RecruiterView/RecruiterView';
import PricingPage from './components/PricingPage/PricingPage';

function App() {
  //const isLoggedIn = false;

  return (
    <>
      <Routes>
        <Route path="/" index element={<MasterPage child={<LandingPage />} />}></Route>
        <Route path="/recruiterview" element={<MasterPage child={<JobSeekerPicker />} />}></Route>
        <Route path="/seeker" element={<MasterPage child={<JobSeekerPicker />} />}></Route>
        <Route path="/pricing" element={<MasterPage child={<PricingPage />} />}></Route>
        <Route
          path="/applyview/:jobseekerid"
          element={<MasterPage child={<RecruiterView />} />}
        ></Route>
        {/* <Route exact path="/login" element={<Login />} /> */}
        <Route exact path="/login" element={<MasterPage child={<Login />} />}></Route>
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/recruitersignup" element={<SignUp recruiter={true} />} />
      </Routes>
    </>
  );
}

export default App;
