import React from 'react';

import './FrustratedJobseekerSection.css';
import frustratedjobseekerhero from '../../images/frustrated-jobseeker.svg';
import { Button } from 'antd';

const FrustratedJobseekerSection = () => (
  <div className="FrustratedJobseekerSection">
    <div className="frustrated-jobseeker-container">
      <div>
        <img src={frustratedjobseekerhero} height={396} width={396} alt="Frustrated Jobseeker" />
      </div>
      <div style={{ alignItems: top }}>
        <div className="main-header" style={{ width: 464, marginBottom: 30, fontSmooth: true }}>
          Applying for jobs should not be a full time job.
        </div>
        <div className="subtitle" style={{ width: 458 }}>
          The average time to complete an application is 4 minutes and 52 seconds ,with an average
          of 51 clicks to get through an application*.
        </div>
        <div style={{ fontSize: '0.8rem' }}>*SHRM.org</div>
        <a href="#howitworks">
          <Button className="frustrated-jobseeker-cta"> Learn More</Button>
        </a>
      </div>
    </div>
  </div>
);

export default FrustratedJobseekerSection;
