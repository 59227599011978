import React, { useState, useContext } from 'react';
import { AppContext } from '../../AppContext';
import './RecruiterView.css';
import { Layout, Button, Input, Space, Modal, Form, message } from 'antd';
import LandingPageHeader from '../LandingPageHeader/LandingPageHeader';
import JobSeekerSidebar from '../JobSeekerSidebar/JobSeekerSidebar';
import JobListings from '../JobListings/JobListings';
import { SearchOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import { createJob } from '../../utils/api';
import { useParams, useNavigate } from 'react-router-dom';

const { Header, Footer, Sider, Content } = Layout;
const { Search } = Input;

const headerStyle = {
  backgroundColor: '#fff',
  lineHeight: '0px'
};

const contentStyle = {
  color: '#fff',
  backgroundColor: '#fff',
  padding: '24px'
};

const siderStyle = {
  backgroundColor: '#fff',
  padding: '24px'
};

const footerStyle = {
  color: '#fff',
  backgroundColor: '#7dbcea',
  textAlign: 'center'
};

const RecruiterView = () => {
  const { currentJobSeeker, setCurrentJobSeeker } = useContext(AppContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { jobseekerid } = useParams();
  const navigate = useNavigate();

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleAddJob = async (values) => {
    try {
      setLoading(true);

      if (!currentJobSeeker || !currentJobSeeker.uid) {
        throw new Error('No job seeker selected or missing UID');
      }

      const jobData = {
        job: {
          role: values.role,
          company: values.company,
          status: 'applied',
          recruiter: values.recruiter,
          linktojob: values.jobLink
        },
        currentJobs: currentJobSeeker.jobsapplied || {}
      };

      await createJob(currentJobSeeker.uid, jobData);

      message.success('Job added successfully');
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error('Error adding job:', error);
      message.error(`Failed to add job: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleEditProfile = async () => {
    message.info('Profile editing coming soon');
  };

  return (
    <div className="RecruiterView">
      <Layout>
        <Header style={headerStyle}>
          <LandingPageHeader />
        </Header>
        <Layout hasSider>
          <Sider width={300} style={siderStyle}>
            <JobSeekerSidebar />
            <div className="recruiter-actions">
              <Space direction="vertical" style={{ width: '100%', marginTop: '20px' }}>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={showModal}
                  block
                  loading={loading}
                >
                  Add New Job
                </Button>
                <Button icon={<EditOutlined />} block onClick={handleEditProfile}>
                  Edit Profile
                </Button>
              </Space>
            </div>
          </Sider>
          <Content style={contentStyle}>
            <div className="content-header">
              <Space direction="vertical" style={{ width: '100%', marginBottom: '20px' }}>
                {/* <Search
                  placeholder="Search jobs..."
                  allowClear
                  enterButton={<SearchOutlined />}
                  size="large"
                  onSearch={handleSearch}
                /> */}
              </Space>
            </div>
            <JobListings admin="true" searchText={searchText} />
          </Content>
        </Layout>
        <Footer style={footerStyle}>
          ApplyStorm Recruiter Dashboard {new Date().getFullYear()}
        </Footer>
      </Layout>

      <Modal
        title="Add New Job"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        confirmLoading={loading}
      >
        <Form form={form} layout="vertical" onFinish={handleAddJob}>
          <Form.Item
            name="role"
            label="Job Role"
            rules={[{ required: true, message: 'Please enter the job role' }]}
          >
            <Input placeholder="Enter job role" />
          </Form.Item>

          <Form.Item
            name="company"
            label="Company"
            rules={[{ required: true, message: 'Please enter the company name' }]}
          >
            <Input placeholder="Enter company name" />
          </Form.Item>

          <Form.Item
            name="recruiter"
            label="Recruiter"
            rules={[{ required: true, message: 'Please enter the recruiter name' }]}
          >
            <Input placeholder="Enter recruiter name" />
          </Form.Item>

          <Form.Item name="jobLink" label="Job Link" rules={[{ required: false }]}>
            <Input placeholder="Enter job URL" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Add Job
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default RecruiterView;
