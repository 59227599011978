import React, { useContext, useState } from 'react';
import './JobSeekerSidebar.css';
import { AppContext } from '../../AppContext';
import { Divider, Tag, Typography, Button, Modal, Form, Input, List, message } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { updateJobSeekerRoles, updateJobSeekerLocations } from '../../utils/api';

const { Text } = Typography;

const planType = (plan) => {
  switch (plan) {
    case 'ultimate_job_seeking_plan':
      return 'ULTIMATE PLAN';
    case 'passive':
      return 'PASSIVE PLAN';
    default:
      return '';
  }
};

const JobSeekerSidebar = () => {
  const { currentJobSeeker, setCurrentJobSeeker } = useContext(AppContext);
  const [isRoleModalVisible, setIsRoleModalVisible] = useState(false);
  const [isLocationModalVisible, setIsLocationModalVisible] = useState(false);
  const [roleForm] = Form.useForm();
  const [locationForm] = Form.useForm();

  const handleAddRole = async (values) => {
    const currentRoles = currentJobSeeker.rolestoapplyfor || [];
    if (currentRoles.length >= 5) {
      message.error('Maximum 5 roles allowed');
      return;
    }
    if (currentRoles.includes(values.role)) {
      message.error('This role already exists');
      return;
    }

    const newRoles = [...currentRoles, values.role];
    try {
      await updateJobSeekerRoles(currentJobSeeker.uid, newRoles);
      setCurrentJobSeeker({ ...currentJobSeeker, rolestoapplyfor: newRoles });
      setIsRoleModalVisible(false);
      roleForm.resetFields();
      message.success('Role added successfully');
    } catch (error) {
      message.error('Failed to add role. Please try again.');
    }
  };

  const handleAddLocation = async (values) => {
    const currentLocations = currentJobSeeker.location?.cities || [];
    if (currentLocations.length >= 5) {
      message.error('Maximum 5 locations allowed');
      return;
    }

    const newLocation = {
      city: values.city,
      state: values.state
    };

    const locationExists = currentLocations.some(
      (loc) => loc.city === newLocation.city && loc.state === newLocation.state
    );

    if (locationExists) {
      message.error('This location already exists');
      return;
    }

    const newLocations = [...currentLocations, newLocation];
    try {
      await updateJobSeekerLocations(currentJobSeeker.uid, newLocations);
      setCurrentJobSeeker({
        ...currentJobSeeker,
        location: { ...currentJobSeeker.location, cities: newLocations }
      });
      setIsLocationModalVisible(false);
      locationForm.resetFields();
      message.success('Location added successfully');
    } catch (error) {
      message.error('Failed to add location. Please try again.');
    }
  };

  const handleDeleteRole = async (roleToDelete) => {
    const newRoles = currentJobSeeker.rolestoapplyfor.filter((role) => role !== roleToDelete);
    try {
      await updateJobSeekerRoles(currentJobSeeker.uid, newRoles);
      setCurrentJobSeeker({ ...currentJobSeeker, rolestoapplyfor: newRoles });
      message.success('Role removed successfully');
    } catch (error) {
      message.error('Failed to remove role. Please try again.');
    }
  };

  const handleDeleteLocation = async (locationToDelete) => {
    const newLocations = currentJobSeeker.location.cities.filter(
      (loc) => !(loc.city === locationToDelete.city && loc.state === locationToDelete.state)
    );
    try {
      await updateJobSeekerLocations(currentJobSeeker.uid, newLocations);
      setCurrentJobSeeker({
        ...currentJobSeeker,
        location: { ...currentJobSeeker.location, cities: newLocations }
      });
      message.success('Location removed successfully');
    } catch (error) {
      message.error('Failed to remove location. Please try again.');
    }
  };

  return (
    <div className="JobSeekerSidebar">
      <div className="jobseeker-sidebar-header">Profile:</div>
      <div className="jobseeker-profile-container">
        <div
          className="profile-image"
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url(${currentJobSeeker.photoURL})`
          }}
        />
        <div>
          <div>
            <div className="jobseeker-name">{currentJobSeeker.name}</div>
          </div>
        </div>
      </div>
      <Divider />

      <div className="jobseeker-sidebar-header">Jobs applied to:</div>
      <div className="jobseeker-membership">
        <Tag color="blue">{planType(currentJobSeeker.membershiptype)}</Tag>
        <div className="jobseeker-jobsapplied">
          {Object.keys(currentJobSeeker.jobsapplied || {}).length} / 300
        </div>
      </div>

      <Divider />
      <div className="jobseeker-sidebar-header">Details:</div>
      <div>
        <Text>
          <a href={`mailto:${currentJobSeeker.email}`}>{currentJobSeeker.email}</a>
        </Text>
      </div>
      <div>
        <Text>
          <a href={currentJobSeeker.resume} target="_blank" rel="noopener noreferrer">
            Resume
          </a>
        </Text>
      </div>
      <div>
        <Text>
          <a href={currentJobSeeker.linkedinprofilelink} target="_blank" rel="noopener noreferrer">
            LinkedIn Profile
          </a>
        </Text>
      </div>

      <Divider />
      <div
        className="jobseeker-sidebar-header"
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <span>Roles to apply for:</span>
        <Button
          type="text"
          icon={<PlusOutlined />}
          onClick={() => setIsRoleModalVisible(true)}
          disabled={currentJobSeeker?.rolestoapplyfor?.length >= 5}
        />
      </div>
      <List
        dataSource={currentJobSeeker?.rolestoapplyfor || []}
        renderItem={(role) => (
          <List.Item
            actions={[
              <Button
                key="delete-role"
                type="text"
                icon={<DeleteOutlined />}
                onClick={() => handleDeleteRole(role)}
              />
            ]}
          >
            {role}
          </List.Item>
        )}
      />

      <Divider />
      <div
        className="jobseeker-sidebar-header"
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <span>Locations:</span>
        <Button
          type="text"
          icon={<PlusOutlined />}
          onClick={() => setIsLocationModalVisible(true)}
          disabled={currentJobSeeker?.location?.cities?.length >= 5}
        />
      </div>
      <List
        dataSource={currentJobSeeker?.location?.cities || []}
        renderItem={(location) => (
          <List.Item
            actions={[
              <Button
                key="delete-location"
                type="text"
                icon={<DeleteOutlined />}
                onClick={() => handleDeleteLocation(location)}
              />
            ]}
          >
            {location.city}, {location.state}
          </List.Item>
        )}
      />

      <Modal
        title="Add Role"
        visible={isRoleModalVisible}
        onCancel={() => {
          setIsRoleModalVisible(false);
          roleForm.resetFields();
        }}
        footer={null}
      >
        <Form form={roleForm} onFinish={handleAddRole}>
          <Form.Item name="role" rules={[{ required: true, message: 'Please enter a role' }]}>
            <Input placeholder="Enter role (e.g., Software Engineer)" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Role
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Add Location"
        visible={isLocationModalVisible}
        onCancel={() => {
          setIsLocationModalVisible(false);
          locationForm.resetFields();
        }}
        footer={null}
      >
        <Form form={locationForm} onFinish={handleAddLocation}>
          <Form.Item name="city" rules={[{ required: true, message: 'Please enter a city' }]}>
            <Input placeholder="Enter city" />
          </Form.Item>
          <Form.Item name="state" rules={[{ required: true, message: 'Please enter a state' }]}>
            <Input placeholder="Enter state" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Location
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default JobSeekerSidebar;
