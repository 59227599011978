import React, { useEffect, useState, useContext } from 'react';
import './JobSeekerPicker.css';
import { getJobSeekers } from '../../utils/api';
import { Card, Form, InputNumber } from 'antd';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext';

const JobSeekerPicker = () => {
  const [allJobSeekers, setAllJobSeekers] = useState([]);
  const { setCurrentJobSeeker } = useContext(AppContext);
  const navigate = useNavigate();

  const selectJobSeeker = async (jobSeeker) => {
    await setCurrentJobSeeker(jobSeeker);
    navigate('/applyview/' + jobSeeker.uid);
  };

  useEffect(() => {
    const fetchjobSeekers = async () => {
      setAllJobSeekers([]);
      try {
        const usersData = await getJobSeekers();
        setAllJobSeekers(usersData);
      } catch (error) {
        console.error('Error fetching job seekers:', error);
      }
    };

    fetchjobSeekers();
  }, []);

  return (
    <div className="jobseeker-list">
      {Object.entries(allJobSeekers).map(([_, jobSeeker]) => (
        <Card
          title={jobSeeker.name}
          bordered={false}
          style={{ width: 300 }}
          key={jobSeeker.uid}
          onClick={() => selectJobSeeker(jobSeeker)}
        >
          <div className="jobseeker-card-item">
            <div>
              <a href={`mailto:${jobSeeker.email}`}>{jobSeeker.email}</a>
            </div>
            <div>Jobs Applied To: {jobSeeker.jobsappliedto}</div>
            {jobSeeker?.rolestoapplyfor && (
              <ul>
                {jobSeeker.rolestoapplyfor.map((role, index) => (
                  <li key={`${jobSeeker.uid}-role-${index}`}>{role}</li>
                ))}
              </ul>
            )}
            {jobSeeker?.salaryExpectations && (
              <div>
                Salary Range: 
                <InputNumber
                  value={jobSeeker.salaryExpectations.min}
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  disabled
                />
                -
                <InputNumber
                  value={jobSeeker.salaryExpectations.max}
                  formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  disabled
                />
              </div>
            )}
          </div>
        </Card>
      ))}
    </div>
  );
};

export default JobSeekerPicker;
