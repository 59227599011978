import React, { useState, useContext } from 'react';
import './Login.css';
import { auth } from '../../utils/firebaseConfig';
import { GoogleAuthProvider } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { fetchUserInfo } from '../../utils/api';
import { AppContext } from '../../AppContext';
import signinLogo from '../../images/branding_guideline_sample_lt_rd_lg.svg';

const Login = () => {
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setCurrentRecruiter, setCurrentJobSeeker } = useContext(AppContext);

  const processLogin = async (user) => {
    try {
      const userInfo = await fetchUserInfo(user);
      if (!userInfo) {
        throw new Error('Failed to fetch user information');
      }

      if (userInfo.role === 'recruiter') {
        await setCurrentRecruiter(userInfo);
        navigate('/recruiter');
      } else {
        await setCurrentJobSeeker(userInfo);
        navigate('/seeker');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError('Failed to process login. Please try again.');
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await auth.signInWithPopup(provider);
      if (result.user) {
        await processLogin(result.user);
      }
    } catch (error) {
      console.error('Google login error:', error);
      setError(error.message);
    }
  };

  return (
    <div>
      <h2>Login</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <div onClick={handleGoogleLogin}>
        <img src={signinLogo} />
      </div>

      <p>
        If you do not have an account, please <a href="/signup">Sign Up Here </a>
      </p>
    </div>
  );
};

export default Login;
