import { database, dbRef, dbOnValue, dbSet, dbUpdate, dbPush, dbRemove } from './dataobject';
import 'firebase/compat/auth';
import 'firebase/compat/database';

const jobseekerDataPath = 'jobseekers';
const usersDataPath = 'users';

export const getJobSeekers = () => {
  return new Promise((resolve, reject) => {
    const currentJobSeekers = dbRef(database, jobseekerDataPath);
    const jobSeekers = [];

    dbOnValue(
      currentJobSeekers,
      (snapshot) => {
        const data = snapshot.val();
        if (data) {
          // Convert the object of users into an array of user info objects
          const jobSeekersInfo = Object.values(data).map((user) => user);
          jobSeekers.push(...jobSeekersInfo);
        }
        // Resolve the promise with the users' info
        resolve(jobSeekers);
      },
      (error) => {
        // Reject the promise if there's an error
        reject(error);
      }
    );

    return jobSeekers;
  });
};

export const fetchUserInfo = (user) => {
  const uid = user.uid;

  const currentUserPath = dbRef(database, usersDataPath + '/' + uid);

  return new Promise((resolve, reject) => {
    var applyStormUser;
    dbOnValue(
      currentUserPath,
      (snapshot) => {
        const data = snapshot.val();
        if (data) {
          console.log('::::::::::' + JSON.stringify(data));
          applyStormUser = data['info'];
        }
        resolve(applyStormUser);
      },
      (error) => {
        reject(error);
      }
    );

    return applyStormUser;
  });
};

// Job management functions
export const deleteJob = async (userId, jobKey) => {
  try {
    const userRef = dbRef(database, `${usersDataPath}/${userId}/info`);
    const snapshot = await dbOnValue(userRef);
    const userData = snapshot.val();
    const updatedJobs = { ...(userData.jobsapplied || {}) };
    delete updatedJobs[jobKey];

    await dbUpdate(userRef, {
      jobsapplied: updatedJobs,
      jobsappliedto: Object.keys(updatedJobs).length
    });

    return true;
  } catch (error) {
    console.error('Error deleting job:', error);
    throw error;
  }
};

export const createJob = async (userId, jobData) => {
  try {
    const userRef = dbRef(database, `${usersDataPath}/${userId}/info`);
    const jobsRef = dbRef(database, 'jobs');
    const newJobKey = await dbPush(jobsRef).key;

    const updatedJobs = { ...(jobData.currentJobs || {}) };
    updatedJobs[newJobKey] = {
      ...jobData.job,
      dateappliedto: new Date().toISOString()
    };

    await dbUpdate(userRef, {
      jobsapplied: updatedJobs,
      jobsappliedto: Object.keys(updatedJobs).length
    });

    return newJobKey;
  } catch (error) {
    console.error('Error creating job:', error);
    throw error;
  }
};

export const updateJob = async (userId, jobKey, jobData) => {
  try {
    const userRef = dbRef(database, `${usersDataPath}/${userId}/info`);
    const snapshot = await dbOnValue(userRef);
    const userData = snapshot.val();
    const updatedJobs = { ...(userData.jobsapplied || {}) };

    updatedJobs[jobKey] = {
      ...updatedJobs[jobKey],
      ...jobData,
      dateappliedto: updatedJobs[jobKey].dateappliedto
    };

    await dbUpdate(userRef, {
      jobsapplied: updatedJobs,
      jobsappliedto: Object.keys(updatedJobs).length
    });

    return true;
  } catch (error) {
    console.error('Error updating job:', error);
    throw error;
  }
};

// Job seeker profile update functions
export const updateJobSeekerRoles = async (userId, roles) => {
  try {
    const userRef = dbRef(database, `${usersDataPath}/${userId}/info`);
    await userRef.update({
      rolestoapplyfor: roles
    });
    return true;
  } catch (error) {
    console.error('Error updating roles:', error);
    throw error;
  }
};

export const updateJobSeekerLocations = async (userId, locations) => {
  try {
    const userRef = dbRef(database, `${usersDataPath}/${userId}/info`);
    await userRef.update({
      location: { cities: locations }
    });
    return true;
  } catch (error) {
    console.error('Error updating locations:', error);
    throw error;
  }
};

export const updateJobSeekerProfile = async (userId, profileData) => {
  try {
    const userRef = dbRef(database, `${usersDataPath}/${userId}/info`);
    await userRef.update({
      ...profileData,
      updatedAt: Date.now()
    });
    return true;
  } catch (error) {
    console.error('Error updating profile:', error);
    throw error;
  }
};
