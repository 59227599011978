// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
  getDatabase,
  onValue,
  ref,
  set,
  push,
  child,
  update,
  remove,
  get
} from 'firebase/database';
import { getAuth } from 'firebase/auth';

// Firebase configuration using environment variables
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);

// Export initialized instances
export { app, auth, database };

// Export database functions with proper promise handling
export const dbRef = ref;

export const dbSet = async (reference, data) => {
  try {
    await set(reference, data);
    return true;
  } catch (error) {
    console.error('Error in dbSet:', error);
    throw error;
  }
};

export const dbPush = (reference) => {
  try {
    return push(reference);
  } catch (error) {
    console.error('Error in dbPush:', error);
    throw error;
  }
};

export const dbUpdate = async (reference, data) => {
  try {
    await update(reference, data);
    return true;
  } catch (error) {
    console.error('Error in dbUpdate:', error);
    throw error;
  }
};

export const dbRemove = async (reference) => {
  try {
    await remove(reference);
    return true;
  } catch (error) {
    console.error('Error in dbRemove:', error);
    throw error;
  }
};

export const dbGet = async (reference) => {
  try {
    return await get(reference);
  } catch (error) {
    console.error('Error in dbGet:', error);
    throw error;
  }
};

export const dbChild = child;

// Export onValue with proper callback handling
export const dbOnValue = (reference, callback, errorCallback) => {
  try {
    return onValue(
      reference,
      (snapshot) => {
        try {
          callback(snapshot);
        } catch (error) {
          if (errorCallback) {
            errorCallback(error);
          } else {
            console.error('Error in dbOnValue callback:', error);
          }
        }
      },
      { onlyOnce: true }
    );
  } catch (error) {
    console.error('Error in dbOnValue:', error);
    if (errorCallback) {
      errorCallback(error);
    }
    throw error;
  }
};

// Export config for other modules
export { firebaseConfig };
