import React, { useState, useContext, useEffect } from 'react';
import { Table, Input, Popconfirm, Form, Typography, Button, Select, message, Card, Row, Col, InputNumber, Collapse, Progress, Tag } from 'antd';
import { BarChart, Bar, PieChart, Pie, Cell, ResponsiveContainer, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import {
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
  CloseOutlined,
  EditOutlined,
  BarChartOutlined
} from '@ant-design/icons';
import { AppContext } from '../../AppContext';
import { deleteJob, createJob, updateJob } from '../../utils/api';
import './JobListings.css';

const { Option } = Select;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  let inputNode;
  if (dataIndex === 'status') {
    inputNode = (
      <Select>
        <Option value="applied">Applied</Option>
        <Option value="need_more_info">Need More Info</Option>
        <Option value="interviewing">Interviewing</Option>
        <Option value="offered">Offered</Option>
        <Option value="rejected">Rejected</Option>
        <Option value="accepted">Accepted</Option>
      </Select>
    );
  } else if (dataIndex === 'salaryMin' || dataIndex === 'salaryMax') {
    inputNode = (
      <InputNumber
        min={0}
        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={value => value.replace(/\$\s?|(,*)/g, '')}
      />
    );
  } else {
    inputNode = <Input />;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const JobListings = ({ admin, searchText, isRecruiter }) => {
  const [form] = Form.useForm();
  const { currentJobSeeker } = useContext(AppContext);
  const { currentRecruiter } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [metricsData, setMetricsData] = useState({
    weeklyVolume: [],
    salaryDistribution: [],
    statusOverview: []
  });
  const [subscriptionMetrics, setSubscriptionMetrics] = useState({
    applied: 0,
    limit: 300 // Default limit, should be fetched from user's subscription
  });

  // Add state for collapse
  const [chartsVisible, setChartsVisible] = useState(true);

  useEffect(() => {
    if (currentJobSeeker && currentJobSeeker.jobsapplied) {
      const jobsArray = Object.entries(currentJobSeeker.jobsapplied).map(([key, job]) => ({
        key,
        ...job
      }));
      setData(jobsArray);

      // Update subscription metrics using count of jobs in jobsapplied
      const appliedCount = Object.keys(currentJobSeeker.jobsapplied).length;
      const limit = 300;
      setSubscriptionMetrics({
        applied: appliedCount,
        limit
      });
    }
  }, [currentJobSeeker]);

  useEffect(() => {
    // Update subscription metrics when data changes
    const appliedCount = data.length;
    const limit = 300; // This should be fetched from user's subscription plan
    setSubscriptionMetrics({
      applied: appliedCount,
      limit,
      remaining: Math.max(0, limit - appliedCount)
    });
  }, [data]);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    const initialValues = {
      status: record.status || 'applied'
    };

    // Only include other fields if user is a recruiter
    if (isRecruiter) {
      initialValues.role = record.role || '';
      initialValues.company = record.company || '';
      initialValues.recruiter = record.recruiter || '';
      initialValues.linktojob = record.linktojob || '';
      initialValues.salaryMin = record.salaryMin || 0;
      initialValues.salaryMax = record.salaryMax || 0;
    }

    form.setFieldsValue(initialValues);
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const handleDelete = async (key) => {
    if (!isRecruiter) return;
    try {
      setLoading(true);
      await deleteJob(key);
      setData(data.filter((item) => item.key !== key));
      message.success('Job deleted successfully');
    } catch (error) {
      console.error('Error deleting job:', error);
      message.error('Failed to delete job: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        const updatedItem = {
          ...item,
          ...row,
        };
        await updateJob(currentJobSeeker.uid, key, updatedItem);
        newData.splice(index, 1, updatedItem);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleAdd = () => {
    const newKey = Date.now().toString();
    const newRow = {
      key: newKey,
      role: 'New Job',
      company: '',
      status: 'applied',
      salaryMin: 0,
      salaryMax: 0,
      dateappliedto: new Date().toISOString()
    };
    
    form.setFieldsValue({
      ...newRow
    });
    
    setData([...data, newRow]);
    setEditingKey(newKey);
  };

  const columns = [
    {
      title: 'Job Title',
      dataIndex: 'role',
      key: 'role',
      width: '25%',
      editable: true,
      filterSearch: true,
      filters: [...new Set(data.map(item => item.role))].map(title => ({ text: title, value: title })),
      onFilter: (value, record) => record.role?.toLowerCase().includes(value.toLowerCase()),
      render: (text, record) => {
        const url = record.linktojob;
        return url ? (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {text}
          </a>
        ) : text
      }
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      width: '20%',
      editable: true,
      filterSearch: true,
      filters: [...new Set(data.map(item => item.company))].map(company => ({ text: company, value: company })),
      onFilter: (value, record) => record.company?.toLowerCase().includes(value.toLowerCase()),
      render: (text) => text
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      editable: true,
      inputType: 'select',
      filters: [
        { text: 'Applied', value: 'applied' },
        { text: 'Need More Info', value: 'need_more_info' },
        { text: 'Interviewing', value: 'interviewing' },
        { text: 'Offered', value: 'offered' },
        { text: 'Rejected', value: 'rejected' },
        { text: 'Accepted', value: 'accepted' }
      ],
      onFilter: (value, record) => record.status === value,
      render: (status) => (
        <Tag className={`status-${status || 'applied'}`}>
          {status === 'need_more_info' ? 'Needs more info' : status}
        </Tag>
      )
    },
    {
      title: 'Salary Range',
      dataIndex: 'salaryMin',
      key: 'salaryRange',
      width: '15%',
      editable: isRecruiter,
      render: (_, record) => {
        if (!record.salaryMin && !record.salaryMax) return 'Not specified';
        return `$${record.salaryMin?.toLocaleString()} - $${record.salaryMax?.toLocaleString()}`;
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <span>
            {editable ? (
              <span>
                <Typography.Link onClick={() => save(record.key)} style={{ marginRight: 8 }}>
                  Save
                </Typography.Link>
                <Popconfirm title="Cancel editing?" onConfirm={cancel}>
                  <a>Cancel</a>
                </Popconfirm>
              </span>
            ) : (
              <span>
                {isRecruiter && (
                  <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)} style={{ marginRight: 8 }}>
                    Edit
                  </Typography.Link>
                )}
                <Popconfirm 
                  title="Delete this job?" 
                  onConfirm={() => handleDelete(record.key)}
                >
                  <Typography.Link type="danger">
                    <DeleteOutlined />
                  </Typography.Link>
                </Popconfirm>
              </span>
            )}
          </span>
        );
      }
    }
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType || 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const filteredData = data.filter((job) => {
    if (!searchText) return true;
    const searchLower = searchText.toLowerCase();
    return (
      job.role.toLowerCase().includes(searchLower) ||
      job.company.toLowerCase().includes(searchLower) ||
      job.recruiter.toLowerCase().includes(searchLower)
    );
  });

  useEffect(() => {
    // Calculate metrics from the jobs data (recruiter-entered data only)
    const calculateMetrics = (jobs) => {
      const now = new Date();
      const fourWeeksAgo = new Date(now.getTime() - (28 * 24 * 60 * 60 * 1000));

      // Weekly application volume with date sorting
      const weeklyVolume = jobs.reduce((acc, job) => {
        const weekOf = new Date(job.dateappliedto).toLocaleDateString('en-US', { month: 'short', day: '2-digit' });
        acc[weekOf] = (acc[weekOf] || 0) + 1;
        return acc;
      }, {});

      // Sort dates
      const sortedWeeklyVolume = Object.entries(weeklyVolume)
        .map(([date, count]) => ({ date, count }))
        .sort((a, b) => new Date(a.date) - new Date(b.date));

      // Salary distribution
      const salaryRanges = {
        '0-50k': 0,
        '50-75k': 0,
        '75-100k': 0,
        '100k+': 0
      };

      jobs.forEach(job => {
        const avgSalary = (job.salaryMin + job.salaryMax) / 2;
        if (avgSalary <= 50000) salaryRanges['0-50k']++;
        else if (avgSalary <= 75000) salaryRanges['50-75k']++;
        else if (avgSalary <= 100000) salaryRanges['75-100k']++;
        else salaryRanges['100k+']++;
      });

      // Status overview
      const statusOverview = {
        applied: 0,
        interviewing: 0,
        offered: 0,
        rejected: 0,
        accepted: 0
      };

      jobs.forEach(job => {
        statusOverview[job.status]++;
      });

      setMetricsData({
        weeklyVolume: sortedWeeklyVolume,
        salaryDistribution: Object.entries(salaryRanges).map(([range, count]) => ({ range, count })),
        statusOverview: Object.entries(statusOverview).map(([status, count]) => ({ status, count }))
      });
    };

    // Calculate metrics whenever jobs data changes
    calculateMetrics(data.filter(job => job.recruiter));
  }, [data]);

  // Add colors for the charts - using accessible color palette
  const STATUS_COLORS = {
    'applied': '#2F4858',      // Dark slate blue
    'need_more_info': '#33658A', // Steel blue
    'interviewing': '#86BBD8',  // Light blue
    'offered': '#758E4F',      // Olive green
    'rejected': '#F26419',     // Orange
    'accepted': '#55828B'      // Teal
  };

  const SALARY_COLORS = {
    '0-50k': '#D1495B',    // Muted red
    '50-75k': '#00798C',   // Teal
    '75-100k': '#30638E',  // Navy
    '100k+': '#003D5B'     // Dark navy
  };

  return (
    <div className="job-listings">
       
      <Collapse defaultActiveKey={['1']} className="dashboard-collapse">
        <Collapse.Panel 
          header={<span><BarChartOutlined /> Application Activity</span>}
          key="1"
        >
          <Row gutter={[16, 16]} className="dashboard-stats">
            <Col xs={24} sm={24} md={6}>
              <Card title="Subscription Usage">
                <div style={{ textAlign: 'center', padding: '20px 0' }}>
                  <div style={{ fontSize: '48px', fontWeight:'bold',color: '#2F4858', marginBottom: '8px' }}>
                  {subscriptionMetrics.applied} / {subscriptionMetrics.limit}
                  </div>
                  <Typography.Text type="secondary">
                  {subscriptionMetrics.remaining} remaining applications
                  </Typography.Text>
                  <div style={{ marginTop: '16px', color: 
                    subscriptionMetrics.remaining <= 30 ? '#F26419' :  // Orange for <= 30
                    subscriptionMetrics.remaining <= 60 ? '#faad14' :  // Yellow for <= 60
                    '#666'                                            // Default gray
                  }}>
                    {/* {subscriptionMetrics.applied} of {subscriptionMetrics.limit} jobs applied */}
                  </div>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={16}>
              <Row gutter={[16, 16]}>

                
                <Col span={8}>
                  <Card title="Weekly Applications">
                    <ResponsiveContainer width="100%" height={200}>
                      <BarChart data={metricsData.weeklyVolume}>
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="count" fill="#2F4858" name="Applications" />
                      </BarChart>
                    </ResponsiveContainer>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="Status Overview">
                    <ResponsiveContainer width="100%" height={200}>
                      <PieChart>
                        <Pie
                          data={metricsData.statusOverview}
                          dataKey="count"
                          nameKey="status"
                          cx="50%"
                          cy="50%"
                          startAngle={180}
                          endAngle={0}
                          innerRadius={60}
                          outerRadius={80}
                          paddingAngle={2}
                        >
                          {metricsData.statusOverview.map((entry, index) => (
                            <Cell 
                              key={`cell-${index}`} 
                              fill={STATUS_COLORS[entry.status] || '#8c8c8c'} 
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend verticalAlign="bottom" height={36} />
                      </PieChart>
                    </ResponsiveContainer>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card title="Salary Ranges">
                    <ResponsiveContainer width="100%" height={200}>
                      <PieChart>
                        <Pie
                          data={metricsData.salaryDistribution}
                          dataKey="count"
                          nameKey="range"
                          cx="50%"
                          cy="50%"
                          innerRadius={60}
                          outerRadius={80}
                          paddingAngle={2}
                        >
                          {metricsData.salaryDistribution.map((entry, index) => (
                            <Cell 
                              key={`cell-${index}`} 
                              fill={SALARY_COLORS[entry.range] || '#8c8c8c'} 
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
      <div style={{ fontSize: '24px', fontWeight:'bold',color: '#2F4858', marginBottom: '8px' }}>
                  My Jobs 
                  </div>
      <div style={{ marginBottom: 16 }}>
        {isRecruiter && (
          <Button 
            type="primary" 
            onClick={handleAdd}
            style={{ marginBottom: 16 }}
          >
            Add Job
          </Button>
        )}
      </div>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={filteredData}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
        />
      </Form>
    </div>
  );
};

export default JobListings;
