import React, { useEffect } from 'react';
import './PricingTable.css';

const StripePricingTable = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return React.createElement('stripe-pricing-table', {
    'pricing-table-id': 'prctbl_1Oi0SYLCwHfudcsoAQcHB1nD',
    'publishable-key':
      'pk_live_51NnX3kLCwHfudcso3owGl9lvlPJeoR5OCxm8o0AZRhrWgq5OI7v4D4X2Lx4VAKY8G7jpXXavmqGES3MqIHIJPtdl00kHWhmTbH'
  });
};
export default StripePricingTable;

{
  /* 
DEVELOP MODE
<script async src="https://js.stripe.com/v3/pricing-table.js"></script>
<stripe-pricing-table pricing-table-id="prctbl_1NnXLWLCwHfudcso3Wxt7vFo"
publishable-key="pk_test_51NnX3kLCwHfudcso6DyFTixsUXnp2zug29DCRNRdSYnIlY6OEXtNltPxGTMKaC3JhziqbanwTN4XNy64iUsTIeu000SY6hVNdf">
</stripe-pricing-table> */
}

// // LIVE
// prctbl_1Oi0SYLCwHfudcsoAQcHB1nD
// pk_live_51NnX3kLCwHfudcso3owGl9lvlPJeoR5OCxm8o0AZRhrWgq5OI7v4D4X2Lx4VAKY8G7jpXXavmqGES3MqIHIJPtdl00kHWhmTbH

//  PROD OLD{
//   "pricing-table-id": 'prctbl_1NpLAzLCwHfudcso4nf17zuO',
//   "publishable-key": 'pk_live_51NnX3kLCwHfudcso3owGl9lvlPJeoR5OCxm8o0AZRhrWgq5OI7v4D4X2Lx4VAKY8G7jpXXavmqGES3MqIHIJPtdl00kHWhmTbH',
//   }
